import React, { createContext, useReducer, ReactNode, useEffect } from 'react';
import { appStateReducer } from './AppReducer';
import {
  Conversation,
  ChatHistoryLoadingState,
  CosmosDBHealth,
  CosmosDBStatus,
  FrontendSettings,
  Navigation,
  fetchNavigation,
  addBot,
  deleteBot,
  fetchRoles,
  addRole,
  deleteRoles,
  fetchUsers,
  updateUser,
  fetchFeedbacks,
  deleteFeedback,
} from '../api';
import { useMatch } from 'react-router-dom';
import { User, UserManager } from 'oidc-client';
// import { authSettings } from '../components/AuthProvider';

export interface AppState {
  isChatHistoryOpen: boolean;
  chatHistoryLoadingState: ChatHistoryLoadingState;
  isCosmosDBAvailable: CosmosDBHealth;
  chatHistory: Conversation[] | null;
  filteredChatHistory: Conversation[] | null;
  currentChat: Conversation | null;
  frontendSettings: FrontendSettings | null;
  pageName: string | undefined;
  navigation: Navigation | null;
  user: User | null | any;
  bot: any;
  botsToDelete: any[];
  sustainabilityChatbotRoles: any[];
  roleToAdd: any;
  rolesToDelete: any[];
  users: any[];
  userToUpdate: any;
  feedbacks: any;
  feedbacksToDelete: any;
  feedbackToPost: any;
  navigationIsLoading: boolean;
}

export type Action =
  | { type: 'TOGGLE_CHAT_HISTORY' }
  | { type: 'SET_COSMOSDB_STATUS'; payload: CosmosDBHealth }
  | {
      type: 'UPDATE_CHAT_HISTORY_LOADING_STATE';
      payload: ChatHistoryLoadingState;
    }
  | { type: 'UPDATE_CURRENT_CHAT'; payload: Conversation | null }
  | { type: 'UPDATE_FILTERED_CHAT_HISTORY'; payload: Conversation[] | null }
  | { type: 'UPDATE_CHAT_HISTORY'; payload: Conversation } // API Call
  | { type: 'UPDATE_CHAT_TITLE'; payload: Conversation } // API Call
  | { type: 'DELETE_CHAT_ENTRY'; payload: string } // API Call
  | { type: 'DELETE_CHAT_HISTORY' } // API Call
  | { type: 'DELETE_CURRENT_CHAT_MESSAGES'; payload: string } // API Call
  | { type: 'FETCH_CHAT_HISTORY'; payload: Conversation[] | null } // API Call
  | { type: 'FETCH_FRONTEND_SETTINGS'; payload: FrontendSettings | null } // API Call
  | { type: 'FETCH_NAVIGATION'; payload: Navigation | null }
  | { type: 'CHANGE_PAGE'; payload: string }
  | { type: 'LOGOUT'; payload: any }
  | { type: 'ADD_BOT'; payload: any }
  | { type: 'DELETE_BOT'; payload: any }
  | { type: 'FETCH_ROLES'; payload: any }
  | { type: 'ADD_ROLE'; payload: any }
  | { type: 'DELETE_ROLES'; payload: any }
  | { type: 'FETCH_USERS'; payload: any[] }
  | { type: 'UPDATE_USER'; payload: any }
  | { type: 'FETCH_FEEDBACK'; payload: any }
  | { type: 'DELETE_FEEDBACK'; payload: any }
  | { type: 'POST_FEEDBACK'; payload: any }
  | { type: 'NAVIGATION_LOADING'; payload: boolean };

export const initialState: AppState = {
  isChatHistoryOpen: false,
  chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
  chatHistory: [],
  filteredChatHistory: [],
  currentChat: null,
  isCosmosDBAvailable: {
    cosmosDB: false,
    status: CosmosDBStatus.NotConfigured,
  },
  frontendSettings: null,
  pageName: '',
  navigation: [],
  user: null,
  bot: null,
  botsToDelete: [],
  sustainabilityChatbotRoles: [],
  roleToAdd: null,
  rolesToDelete: [],
  users: [],
  userToUpdate: null,
  feedbacks: [],
  feedbacksToDelete: [],
  feedbackToPost: null,
  navigationIsLoading: true,
};

export const AppStateContext = createContext<
  | {
      state: AppState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

type AppStateProviderProps = {
  children: ReactNode;
};

export const AppStateProvider: React.FC<AppStateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  useEffect(() => {
    const getNavigation = async () => {
      if (!state.navigation?.length) {
        dispatch({
          type: 'NAVIGATION_LOADING',
          payload: true,
        });

        fetchNavigation()
          .then((response: any) => {
            if (response.error) throw new Error(response.message);
            if (response.length > 0) {
              dispatch({
                type: 'FETCH_NAVIGATION',
                payload: response as Navigation,
              });
            }
            dispatch({
              type: 'NAVIGATION_LOADING',
              payload: false,
            });
          })
          .catch((err) => {
            dispatch({
              type: 'FETCH_NAVIGATION',
              payload: [],
            });
            dispatch({
              type: 'NAVIGATION_LOADING',
              payload: false,
            });
            console.error('There was an issue fetching your data.');
          });
      }
    };
    getNavigation();
  }, [state.navigation]);

  useEffect(() => {
    if (state.bot) {
      addBot(state.bot)
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          dispatch({
            type: 'NAVIGATION_LOADING',
            payload: true,
          });

          fetchNavigation()
            .then((response: any) => {
              if (response.error) throw new Error(response.message);
              dispatch({ type: 'ADD_BOT', payload: null });
              dispatch({
                type: 'FETCH_NAVIGATION',
                payload: response as Navigation,
              });
              dispatch({
                type: 'NAVIGATION_LOADING',
                payload: false,
              });
              
            })
            .catch((err) => {
              dispatch({
                type: 'NAVIGATION_LOADING',
                payload: true,
              });
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.bot]);
  useEffect(() => {
    if (state.botsToDelete.length > 0) {
      deleteBot(state.botsToDelete)
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          console.log({ response });
          dispatch({ type: 'DELETE_BOT', payload: [] });
          dispatch({
            type: 'NAVIGATION_LOADING',
            payload: true,
          });
          fetchNavigation()
            .then((response: any) => {
              if (response.error) throw new Error(response.message);
              dispatch({
                type: 'FETCH_NAVIGATION',
                payload: response as Navigation,
              });
              dispatch({
                type: 'NAVIGATION_LOADING',
                payload: false,
              });
            })
            .catch((err) => {
              console.log(err);
              dispatch({
                type: 'NAVIGATION_LOADING',
                payload: false,
              });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.botsToDelete]);

  useEffect(() => {
    console.log("state.pageName", state.pageName);
    if (
      state.pageName === 'admin/roleManagement' ||
      state.pageName === 'admin/userManagement' ||
      state.pageName === 'admin/botManagement'  
      // state.pageName === 'admin/sustainabilityChatbot'
    ) {

      console.log("here roles");
      fetchRoles()
        .then(async (response) => {
          if (!response.ok) throw new Error(response.statusText);
          const roles = await response.json();

          dispatch({ type: 'FETCH_ROLES', payload: roles });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (
      state.pageName === 'admin/userManagement' ||
      state.pageName === 'admin/feedbacks'
    ) {
      fetchUsers()
        .then(async (response) => {
          if (!response.ok) throw new Error(response.statusText);
          const users = await response.json();
          dispatch({ type: 'FETCH_USERS', payload: users });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (state.pageName === 'admin/feedbacks') {
      fetchFeedbacks().then(async (response) => {
        if (!response.ok) throw new Error(response.statusText);
        const feedbacks = await response.json();
        dispatch({ type: 'FETCH_FEEDBACK', payload: feedbacks });
      });
    }
  }, [state.pageName]);

  useEffect(() => {
    if (state.roleToAdd) {
      addRole(state.roleToAdd)
        .then(async (response) => {
          if (!response.ok) throw new Error(response.statusText);
          fetchRoles()
            .then(async (response) => {
              if (!response.ok) throw new Error(response.statusText);
              const roles = await response.json();
              dispatch({ type: 'FETCH_ROLES', payload: roles });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.roleToAdd]);

  useEffect(() => {
    if (state.rolesToDelete.length > 0) {
      deleteRoles(state.rolesToDelete)
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);

          dispatch({ type: 'DELETE_ROLES', payload: [] });
          fetchRoles()
            .then(async (response) => {
              if (!response.ok) throw new Error(response.statusText);
              const roles = await response.json();
              dispatch({ type: 'FETCH_ROLES', payload: roles });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.rolesToDelete]);

  useEffect(() => {
    if (state.userToUpdate) {
      updateUser(state.userToUpdate)
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          fetchUsers()
            .then(async (response) => {
              if (!response.ok) throw new Error(response.statusText);
              const users = await response.json();
              dispatch({ type: 'FETCH_USERS', payload: users });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.userToUpdate]);

  useEffect(() => {
    if (state.feedbackToPost) {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/feedbacks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback: state.feedbackToPost }),
      })
        .then(async (response) => {
          if (!response.ok) throw new Error(response.statusText);
          dispatch({ type: 'POST_FEEDBACK', payload: null });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.feedbackToPost]);

  useEffect(() => {
    if (state.feedbacksToDelete.length > 0) {
      deleteFeedback(state.feedbacksToDelete)
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          dispatch({ type: 'DELETE_FEEDBACK', payload: [] });
          fetchFeedbacks()
            .then(async (response) => {
              if (!response.ok) throw new Error(response.statusText);
              const feedbacks = await response.json();
              dispatch({ type: 'FETCH_FEEDBACK', payload: feedbacks });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state.feedbacksToDelete]);
  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};
