import React, { useContext, useEffect } from 'react';
import {
  Avatar,
  Button,
  Chip,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { AppStateContext } from '../../SustainabilityChatbot/State/AppProvider';
import { DataGrid } from '@mui/x-data-grid';

export default function RoleManagement() {
  const appStateContext = useContext(AppStateContext);
  const validationSchema = yup.object().shape({
    name: yup.string().required('The Role Name is Required'),
  });
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const initialValues = {
    name: '',
  };

  useEffect(() => {
    appStateContext?.dispatch({
      type: 'CHANGE_PAGE',
      payload: 'admin/roleManagement',
    });
  }, []);

  //appStateContext?.state?.sustainabilityChatbotRoles?.length === 0 ? null : 
  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(
          form,
          { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any },
        ) => {
          console.log(form);
          appStateContext?.dispatch({
            type: 'ADD_ROLE',
            payload: form,
          });

          resetForm();
        }}
      >
        {({ errors, touched, isSubmitting, handleSubmit, submitForm }) => (
          <Form>
            <Grid container spacing={2} p={1}>
              {/* <Grid md={10} item p={1}></Grid> */}
              <Stack
                direction="row"
                spacing={2}
                p={1}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting}
                >
                  Add
                </Button>
              </Stack>
              {/* </Grid> */}

              <Grid xs={12} item>
                <Field name="name">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Name"
                      error={Boolean(errors['name'] && touched['name'])}
                      helperText={Boolean(touched['name']) && errors['name']}
                    ></TextField>
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container spacing={2} p={1}>
        <Grid md={3} item p={1}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              appStateContext?.dispatch({
                type: 'DELETE_ROLES',
                payload: selectedIds,
              });
            }}
            disabled={selectedIds.length === 0}
          >
            Delete Selection
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        autoHeight
        rows={appStateContext?.state?.sustainabilityChatbotRoles || []}
        columns={[
          { field: 'id', headerName: 'ID', width: 70 },
          { field: 'name', headerName: 'Name', width: 130 },
          {
            field: 'bots',
            headerName: 'Bots',
            width: 500,
            renderCell: (params: any) => (
              <Stack direction="row" spacing={1}>
                {params.value.map((bot: any) => (
                  <Chip
                    key={bot.id}
                    label={bot.name}
                    avatar={
                      <Avatar
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/logos/${bot.name}`}
                      />
                    }
                  />
                ))}
              </Stack>
            ),
          },
        ]}
        checkboxSelection
        //@ts-ignore
        onRowSelectionModelChange={(ids: any) => {
          setSelectedIds(ids);
        }}
      />
    </>
  );
}
