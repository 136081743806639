import { useEffect, useMemo, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { FontIcon, Stack, Text } from '@fluentui/react';

import styles from './Answer.module.css';

import { AskResponse, Citation } from '../api';
import { parseAnswer } from './AnswerParser';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';
import rehypeRaw from 'rehype-raw';
import { Box } from '@mui/material';

interface Props {
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
}

export const Answer = ({ answer, onCitationClicked }: Props) => {
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);

  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);

  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false,
  ) => {
    let citationFilename = '';

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation.filepath.length > filePathTruncationLimit) {
        const citationLength = citation.filepath.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20,
        )}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1
          }`;
      } else {
        citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1
          }`;
      }
    } else if (citation.filepath && citation.reindex_id) {
      citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };

  return (
    <>
      <Stack>
        <Stack.Item grow>
          <Box
            sx={{
              '& a[data-has-citation]': {
                color: (theme) => theme.palette.primary.main,
                textDecoration: 'underline',
                cursor: 'pointer',
                marginRight: '5px',
              },
            }}
            onClick={(e: any) => {
              e.target.dataset.citation &&
                onCitationClicked(
                  parsedAnswer.citations[
                  parseInt(e.target.dataset.citation) - 1
                  ],
                );
            }}
          >
            <ReactMarkdown
              //   linkTarget="_blank"
              //@ts-ignore
              remarkPlugins={[remarkGfm, supersub]}
              //@ts-ignore
              rehypePlugins={[rehypeRaw]}
              children={parsedAnswer.markdownFormatText}
              className={styles.answerText}
            />
          </Box>
        </Stack.Item>
        <Stack horizontal className={styles.answerFooter}>
          {!!parsedAnswer.citations.length && (
            <Stack.Item
              onKeyDown={(e) =>
                e.key === 'Enter' || e.key === ' '
                  ? toggleIsRefAccordionOpen()
                  : null
              }
            >
              <Stack style={{ width: '100%' }}>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                >
                  <Text
                    className={styles.accordionTitle}
                    onClick={toggleIsRefAccordionOpen}
                    aria-label="Open references"
                    // tabIndex={0}
                    role="button"
                  >
                    <span>
                      {parsedAnswer.citations.length > 1
                        ? parsedAnswer.citations.length + ' references'
                        : '1 reference'}
                    </span>
                  </Text>
                  {/* <FontIcon className={styles.accordionIcon}
                                onClick={handleChevronClick} iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                /> */}
                </Stack>
              </Stack>
            </Stack.Item>
          )}
          <Stack.Item className={styles.answerDisclaimerContainer}>
            <span className={styles.answerDisclaimer}>
              AI-generated content may be incorrect
            </span>
          </Stack.Item>
        </Stack>
        {chevronIsExpanded && (
          <div
            style={{
              marginTop: 8,
              display: 'flex',
              flexFlow: 'wrap column',
              maxHeight: '150px',
              gap: '4px',
            }}
          >
            {parsedAnswer.citations.map((citation, idx) => {
              return (
                <span
                  title={createCitationFilepath(citation, ++idx)}
                  //   tabIndex={0}
                  role="link"
                  key={idx}
                  onClick={() => onCitationClicked(citation)}
                  //   onKeyDown={(e) =>
                  //     e.key === "Enter" || e.key === " "
                  //       ? onCitationClicked(citation)
                  //       : null
                  //   }
                  className={styles.citationContainer}
                  aria-label={createCitationFilepath(citation, idx)}
                >
                  {/* <div className={styles.citation}>{idx}</div> */}
                  {createCitationFilepath(citation, idx, true)}
                </span>
              );
            })}
          </div>
        )}
      </Stack>
    </>
  );
};
