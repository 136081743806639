import {
  useRef,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from 'react';
import { DialogType, Stack, IconButton } from '@fluentui/react';
import { ShieldLockRegular, ErrorCircleRegular } from '@fluentui/react-icons';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import uuid from 'react-uuid';
import { isEmpty } from 'lodash-es';
import styles from './Chat.module.css';
import { Button, Divider, Typography } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  getUserInfo,
  Conversation,
  historyGenerate,
  historyUpdate,
  historyClear,
  ChatHistoryLoadingState,
  CosmosDBStatus,
  ErrorMessage,
} from '../../SustainabilityChatbot/api/';
import { Answer } from '../../SustainabilityChatbot/Answer';
import { QuestionInput } from '../../SustainabilityChatbot/QuestionInput';
import { ChatHistoryPanel } from '../../admin/SustainabilityChatbotManager/ChatHistory/ChatHistoryPanel';
import { AppStateContext } from '../../SustainabilityChatbot/State/AppProvider';
import { useBoolean } from '@fluentui/react-hooks';
import { grey } from '@mui/material/colors';
import MessageBox from './MessageBox';

import HeaderLogo from './HeaderLogo';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import FeedbackDialog from './FeedbackDialog';
import { Stack as MuiStack } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { Dialog } from '@mui/material';
import DialogTitle from '../../admin/SustainabilityChatbotManager/UserEditDialog/DialogTitle';
const enum messageStatus {
  NotRunning = 'Not Running',
  Processing = 'Processing',
  Done = 'Done',
}

const Chat = ({ title, prompts, index, systemMessage, botId }: any) => {
  const appStateContext = useContext(AppStateContext);
  const AUTH_ENABLED =
    appStateContext?.state.frontendSettings?.auth_enabled === 'true';
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [activeCitation, setActiveCitation] = useState<Citation>();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [processMessages, setProcessMessages] = useState<messageStatus>(
    messageStatus.NotRunning,
  );
  const [clearingChat, setClearingChat] = useState<boolean>(false);
  const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
  const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [botAnswer, setBotAnswer] = useState<string>('');
  const [userMessage, setUserMessage] = useState<string>('');

  const errorDialogContentProps = {
    type: DialogType.close,
    title: errorMsg?.title,
    closeButtonAriaLabel: 'Close',
    subText: errorMsg?.subtitle,
  };

  const modalProps = {
    titleAriaId: 'labelId',
    subtitleAriaId: 'subTextId',
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  const [ASSISTANT, TOOL, ERROR] = ['assistant', 'tool', 'error'];

  const [displaySendFeedbackMessage, setDisplaySendFeedbackMessage] =
    useState(false);

  useEffect(() => {
    if (
      appStateContext?.state.isCosmosDBAvailable?.status ===
      CosmosDBStatus.NotWorking &&
      appStateContext.state.chatHistoryLoadingState ===
      ChatHistoryLoadingState.Fail &&
      hideErrorDialog
    ) {
      let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`;
      setErrorMsg({
        title: 'Chat history is not enabled',
        subtitle: subtitle,
      });
      toggleErrorDialog();
    }
  }, [appStateContext?.state.isCosmosDBAvailable]);

  const handleErrorDialogClose = () => {
    toggleErrorDialog();
    setTimeout(() => {
      setErrorMsg(null);
    }, 500);
  };

  useEffect(() => {
    setIsLoading(
      appStateContext?.state.chatHistoryLoadingState ===
      ChatHistoryLoadingState.Loading,
    );
  }, [appStateContext?.state.chatHistoryLoadingState]);

  const getUserInfoList = async () => {
    if (!AUTH_ENABLED) {
      setShowAuthMessage(false);
      return;
    }
    const userInfoList = await getUserInfo();
    if (userInfoList.length === 0 && window.location.hostname !== '127.0.0.1') {
      setShowAuthMessage(true);
    } else {
      setShowAuthMessage(false);
    }
  };

  let assistantMessage = {} as ChatMessage;
  let toolMessage = {} as ChatMessage;
  let assistantContent = '';

  const processResultMessage = (
    resultMessage: ChatMessage,
    userMessage: ChatMessage,
    conversationId?: string,
  ) => {
    if (resultMessage.role === ASSISTANT) {
      assistantContent += resultMessage.content;
      assistantMessage = resultMessage;
      assistantMessage.content = assistantContent;
    }

    if (resultMessage.role === TOOL) toolMessage = resultMessage;

    if (!conversationId) {
      isEmpty(toolMessage)
        ? setMessages([...messages, userMessage, assistantMessage])
        : setMessages([
          ...messages,
          userMessage,
          toolMessage,
          assistantMessage,
        ]);
    } else {
      isEmpty(toolMessage)
        ? setMessages([...messages, assistantMessage])
        : setMessages([...messages, toolMessage, assistantMessage]);
    }
  };

  const makeApiRequestWithoutCosmosDB = async (
    question: string,
    conversationId?: string,
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);
    console.log(appStateContext?.state);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: 'user',
      content: question,
      date: new Date().toISOString(),
    };

    let conversation: Conversation | null | undefined;
    if (!conversationId) {
      conversation = {
        id: conversationId ?? uuid(),
        title: question,
        messages: [userMessage],
        date: new Date().toISOString(),
      };
    } else {
      conversation = appStateContext?.state?.currentChat;
      if (!conversation) {
        console.error('Conversation not found.');
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController,
        );
        return;
      } else {
        conversation.messages.push(userMessage);
      }
    }

    appStateContext?.dispatch({
      type: 'UPDATE_CURRENT_CHAT',
      payload: conversation,
    });
    setMessages(conversation.messages);

    const request: ConversationRequest = {
      bot: { index, systemMessage },
      messages: [
        ...conversation.messages.filter((answer) => answer.role !== ERROR),
      ],
    };

    let result = {} as ChatResponse;
    try {
      const response = await conversationApi(request, abortController.signal);
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = '';

        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) {
            const content =
              result.choices[0].messages[result.choices[0].messages.length - 1]
                .content;
            if (
              content
                .toLowerCase()
                .includes(
                  "apologies, i don't have an answer for your question.",
                ) ||
              content
                .toLowerCase()
                .includes(
                  'the requested information is not found in the retrieved data',
                ) ||
              content
                .toLocaleLowerCase()
                .includes('the requested information is not available')
            ) {
              appStateContext?.dispatch({
                type: 'POST_FEEDBACK',
                payload: {
                  botAnswer: `${content}`,
                  bot: { id: botId },
                  userMessage: conversation?.messages
                    ?.filter((m) => m.role === 'user')
                    .pop()?.content,
                  isLiked: false,
                  isDisliked: true,
                  feedback: 'auto generated feedback',
                  reasons: [],
                },
              });
            }
            break;
          }
          var text = new TextDecoder('utf-8').decode(value);
          const objects = text.split('\n');
          objects.forEach((obj) => {
            try {
              runningText += obj.replace('data:', '');
              result = JSON.parse(runningText);
              result.choices[0].messages.forEach((obj) => {
                obj.id = uuid();
                obj.date = new Date().toISOString();
              });
              setShowLoadingMessage(false);
              result.choices[0].messages.forEach((resultObj) => {
                processResultMessage(resultObj, userMessage, conversationId);
              });

              runningText = '';
            } catch { }
          });
        }
        conversation.messages.push(toolMessage, assistantMessage);
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: conversation,
        });
        setMessages([...messages, toolMessage, assistantMessage]);
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage =
          'An error occurred. Please try again. If the problem persists, please contact the site administrator.';
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === 'string') {
          errorMessage = result.error;
        }
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString(),
        };
        conversation.messages.push(errorChatMsg);
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: conversation,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController,
      );
      setProcessMessages(messageStatus.Done);
    }

    return abortController.abort();
  };

  const makeApiRequestWithCosmosDB = async (
    question: string,
    conversationId?: string,
  ) => {
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: ChatMessage = {
      id: uuid(),
      role: 'user',
      content: question,
      date: new Date().toISOString(),
    };

    //api call params set here (generate)
    let request: ConversationRequest;
    let conversation;
    if (conversationId) {
      conversation = appStateContext?.state?.chatHistory?.find(
        (conv) => conv.id === conversationId,
      );
      if (!conversation) {
        console.error('Conversation not found.');
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController,
        );
        return;
      } else {
        conversation.messages.push(userMessage);
        request = {
          bot: { index, systemMessage },
          messages: [
            ...conversation.messages.filter((answer) => answer.role !== ERROR),
          ],
        };
      }
    } else {
      request = {
        bot: { index, systemMessage },
        messages: [userMessage].filter((answer) => answer.role !== ERROR),
      };
      setMessages(request.messages);
    }
    let result = {} as ChatResponse;
    try {
      const response = conversationId
        ? await historyGenerate(request, abortController.signal, conversationId)
        : await historyGenerate(request, abortController.signal);
      if (!response?.ok) {
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content:
            "There was an error generating a response. Chat history can't be saved at this time. If the problem persists, please contact the site administrator.",
          date: new Date().toISOString(),
        };
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            (conv) => conv.id === conversationId,
          );
          if (!resultConversation) {
            console.error('Conversation not found.');
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController,
            );
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          setMessages([...messages, userMessage, errorChatMsg]);
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            (a) => a !== abortController,
          );
          return;
        }
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: resultConversation,
        });
        setMessages([...resultConversation.messages]);
        return;
      }
      if (response?.body) {
        console.log(response);
        const reader = response.body.getReader();
        let runningText = '';

        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) break;
          var text = new TextDecoder('utf-8').decode(value);
          const objects = text.split('\n');
          objects.forEach((obj) => {
            try {
              runningText += obj;
              result = JSON.parse(runningText);
              result.choices[0].messages.forEach((obj) => {
                obj.id = uuid();
                obj.date = new Date().toISOString();
              });
              setShowLoadingMessage(false);
              result.choices[0].messages.forEach((resultObj) => {
                processResultMessage(resultObj, userMessage, conversationId);
              });
              runningText = '';
            } catch { }
          });
        }

        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            (conv) => conv.id === conversationId,
          );
          if (!resultConversation) {
            console.error('Conversation not found.');
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController,
            );
            return;
          }
          isEmpty(toolMessage)
            ? resultConversation.messages.push(assistantMessage)
            : resultConversation.messages.push(toolMessage, assistantMessage);
        } else {
          console.log(result);
          resultConversation = {
            id: result?.history_metadata?.conversation_id,
            title: result?.history_metadata?.title,
            messages: [userMessage],
            date: result?.history_metadata?.date,
          };
          isEmpty(toolMessage)
            ? resultConversation.messages.push(assistantMessage)
            : resultConversation.messages.push(toolMessage, assistantMessage);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            (a) => a !== abortController,
          );
          return;
        }
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: resultConversation,
        });
        isEmpty(toolMessage)
          ? setMessages([...messages, assistantMessage])
          : setMessages([...messages, toolMessage, assistantMessage]);
      }
    } catch (e) {
      console.log(e);
      if (!abortController.signal.aborted) {
        let errorMessage =
          'An error occurred. Please try again. If the problem persists, please contact the site administrator.';
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === 'string') {
          errorMessage = result.error;
        }
        let errorChatMsg: ChatMessage = {
          id: uuid(),
          role: ERROR,
          content: errorMessage,
          date: new Date().toISOString(),
        };
        let resultConversation;
        if (conversationId) {
          resultConversation = appStateContext?.state?.chatHistory?.find(
            (conv) => conv.id === conversationId,
          );
          if (!resultConversation) {
            console.error('Conversation not found.');
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController,
            );
            return;
          }
          resultConversation.messages.push(errorChatMsg);
        } else {
          if (!result.history_metadata) {
            console.error('Error retrieving data.', result);
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(
              (a) => a !== abortController,
            );
            return;
          }
          resultConversation = {
            id: result.history_metadata.conversation_id,
            title: result.history_metadata.title,
            messages: [userMessage],
            date: result.history_metadata.date,
          };
          resultConversation.messages.push(errorChatMsg);
        }
        if (!resultConversation) {
          setIsLoading(false);
          setShowLoadingMessage(false);
          abortFuncs.current = abortFuncs.current.filter(
            (a) => a !== abortController,
          );
          return;
        }
        appStateContext?.dispatch({
          type: 'UPDATE_CURRENT_CHAT',
          payload: resultConversation,
        });
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController,
      );
      setProcessMessages(messageStatus.Done);
    }
    return abortController.abort();
  };

  const clearChat = async () => {
    setClearingChat(true);
    if (
      appStateContext?.state.currentChat?.id &&
      appStateContext?.state.isCosmosDBAvailable.cosmosDB
    ) {
      let response = await historyClear(appStateContext?.state.currentChat.id);
      if (!response.ok) {
        setErrorMsg({
          title: 'Error clearing current chat',
          subtitle:
            'Please try again. If the problem persists, please contact the site administrator.',
        });
        toggleErrorDialog();
      } else {
        appStateContext?.dispatch({
          type: 'DELETE_CURRENT_CHAT_MESSAGES',
          payload: appStateContext?.state.currentChat.id,
        });
        appStateContext?.dispatch({
          type: 'UPDATE_CHAT_HISTORY',
          payload: appStateContext?.state.currentChat,
        });
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        setMessages([]);
      }
    }
    setClearingChat(false);
  };

  const newChat = () => {
    setProcessMessages(messageStatus.Processing);
    setMessages([]);
    setIsCitationPanelOpen(false);
    setActiveCitation(undefined);
    appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
    setProcessMessages(messageStatus.Done);
  };

  const stopGenerating = () => {
    abortFuncs.current.forEach((a) => a.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (appStateContext?.state.currentChat) {
      setMessages(appStateContext.state.currentChat.messages);
    } else {
      stopGenerating();
      setMessages([]);
    }
  }, [appStateContext?.state.currentChat]);

  useLayoutEffect(() => {
    const saveToDB = async (
      messages: ChatMessage[],
      id: string,
      title: string,
    ) => {
      const response = await historyUpdate(messages, id, botId, title);
      return response;
    };

    if (
      appStateContext &&
      appStateContext.state.currentChat &&
      processMessages === messageStatus.Done
    ) {
      if (!appStateContext?.state.currentChat?.messages) {
        console.error('Failure fetching current chat state.');
        return;
      }
      saveToDB(
        appStateContext.state.currentChat.messages?.filter((m) => m.content),
        appStateContext.state.currentChat.id,
        appStateContext.state.currentChat.title,
      )
        .then((res) => {
          if (!res.ok) {
            let errorMessage =
              "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
            let errorChatMsg: ChatMessage = {
              id: uuid(),
              role: ERROR,
              content: errorMessage,
              date: new Date().toISOString(),
            };
            if (!appStateContext?.state.currentChat?.messages) {
              let err: Error = {
                ...new Error(),
                message: 'Failure fetching current chat state.',
              };
              throw err;
            }
            setMessages([
              ...appStateContext?.state.currentChat?.messages,
              errorChatMsg,
            ]);
          }
          return res as Response;
        })
        .catch((err) => {
          console.error('Error: ', err);
          let errRes: Response = {
            ...new Response(),
            ok: false,
            status: 500,
          };
          return errRes;
        });

      appStateContext?.dispatch({
        type: 'UPDATE_CHAT_HISTORY',
        payload: appStateContext.state.currentChat,
      });
      setMessages(appStateContext.state.currentChat.messages);
      setProcessMessages(messageStatus.NotRunning);
    }
  }, [processMessages]);

  useEffect(() => {
    if (AUTH_ENABLED !== undefined) getUserInfoList();
  }, [AUTH_ENABLED]);

  useLayoutEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [showLoadingMessage, processMessages]);

  const onShowCitation = (citation: Citation) => {
    setActiveCitation(citation);
    setIsCitationPanelOpen(true);
  };

  const onViewSource = (citation: Citation) => {
    if (citation.url && !citation.url.includes('blob.core')) {
      window.open(citation.url, '_blank');
    }
  };

  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role && message?.role === 'tool') {
      try {
        const toolMessage = JSON.parse(message.content) as ToolMessageContent;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  const disabledButton = () => {
    return (
      isLoading || (messages && messages.length === 0) /*||
      clearingChat ||
      appStateContext?.state.chatHistoryLoadingState ===
        ChatHistoryLoadingState.Loading*/
    );
  };

  useEffect(() => {
    if (appStateContext?.state?.pageName) {
      stopGenerating();
      appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
    }
  }, [appStateContext?.state?.pageName]);

  return (
    <Box>
      {/* {showAuthMessage ? (
        <Stack className={styles.chatEmptyState}>
          <ShieldLockRegular
            className={styles.chatIcon}
            style={{ color: "darkorange", height: "200px", width: "200px" }}
          />
          <h1 className={styles.chatEmptyStateTitle}>
            Authentication Not Configured
          </h1>
          <h2 className={styles.chatEmptyStateSubtitle}>
            This app does not have authentication configured. Please add an
            identity provider by finding your app in the
            <a href="https://portal.azure.com/" target="_blank">
              {" "}
              Azure Portal{" "}
            </a>
            and following
            <a
              href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
              target="_blank"
            >
              {" "}
              these instructions
            </a>
            .
          </h2>
          <h2
            className={styles.chatEmptyStateSubtitle}
            style={{ fontSize: "20px" }}
          >
            <strong>
              Authentication configuration takes a few minutes to apply.{" "}
            </strong>
          </h2>
          <h2
            className={styles.chatEmptyStateSubtitle}
            style={{ fontSize: "20px" }}
          >
            <strong>
              If you deployed in the last 10 minutes, please wait and reload the
              page after 10 minutes.
            </strong>
          </h2>
        </Stack>
      ) : ( */}
      <>
        {!messages || messages.length < 1 ? (
          <HeaderLogo title={title} />
        ) : (
          <Box
            component="div"
            sx={{
              p: 0,
              width: 'auto',
              zIndex: (theme) => theme.zIndex.appBar + 1,
              height: 'calc(100vh - 290px)',
              // height: '400px',
              mt: (theme) => theme.spacing(3),
              overflow: 'hidden',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            <Box
              style={{ marginBottom: isLoading ? '40px' : '0px' }}
              sx={{ p: 2, ml: (theme) => `calc(${theme.spacing(6)} + 1px)` }}
            >
              {messages.map((answer, index) => (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent:
                        answer.role === 'user' ? 'flex-end' : 'flex-start',
                      p: 0.5,
                    }}
                  >
                    {answer.role === 'user' ? (
                      <MessageBox
                        direction="right"
                        isFirst={messages[index - 1]?.role !== 'user'}
                        isLast={messages[index + 1]?.role !== 'user'}
                        hasNext={messages[index + 1]?.role === 'user'}
                      >
                        {answer.content}
                      </MessageBox>
                    ) : answer.role === 'assistant' ? (
                      <MessageBox direction="left">
                        <Answer
                          answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(
                              messages[index - 1],
                            ),
                          }}
                          onCitationClicked={(c) => onShowCitation(c)}
                        />
                        {!isLoading && (
                          <MuiStack
                            direction="row"
                            spacing={0}
                            justifyContent={'flex-end'}
                          >
                            <MuiIconButton
                              color="primary"
                              onClick={() => {
                                appStateContext?.dispatch({
                                  type: 'POST_FEEDBACK',
                                  payload: {
                                    botAnswer: `${answer.content}`,
                                    bot: { id: botId },
                                    userMessage: messages
                                      .filter((m) => {
                                        return m.role === 'user';
                                      })
                                      .pop()?.content,
                                    isLiked: true,
                                    isDisliked: false,
                                    feedback: '',
                                    reasons: [],
                                  },
                                });
                                setDisplaySendFeedbackMessage(true);
                                setTimeout(() => {
                                  setDisplaySendFeedbackMessage(false);
                                }, 3000);
                              }}
                            >
                              <ThumbUpIcon color="primary" />
                            </MuiIconButton>
                            <MuiIconButton
                              color="primary"
                              onClick={() => {
                                setBotAnswer(answer.content);
                                const lastUserMessage = messages
                                  .filter((m) => {
                                    return m.role === 'user';
                                  })
                                  .pop();
                                setUserMessage(lastUserMessage?.content ?? '');
                                setFeedbackOpen(true);
                              }}
                            >
                              <ThumbDownIcon color="primary" />
                            </MuiIconButton>
                          </MuiStack>
                        )}
                      </MessageBox>
                    ) : //  </div>
                      answer.role === ERROR ? (
                        <div className={styles.chatMessageError}>
                          <Stack
                            horizontal
                            className={styles.chatMessageErrorContent}
                          >
                            <ErrorCircleRegular
                              className={styles.errorIcon}
                              style={{ color: 'rgba(182, 52, 67, 1)' }}
                            />
                            <span>Error</span>
                          </Stack>
                          <span className={styles.chatMessageErrorContent}>
                            {answer.content}
                          </span>
                        </div>
                      ) : null}
                  </Box>
                </>
              ))}
              <div ref={chatMessageStreamEnd} />
            </Box>
          </Box>
        )}
        {/* <Dialog
          hidden={hideErrorDialog}
          onDismiss={handleErrorDialogClose}
          dialogContentProps={errorDialogContentProps}
          modalProps={modalProps}
        ></Dialog> */}
        <Box
          sx={{
            width: '100%',
            zIndex: (theme) => theme.zIndex.appBar - 4,
            position: 'fixed',
            bottom: '8px',
            backgroundColor: 'white',
          }}
        >
          <Divider />
          <Box sx={{ width: '100%' }}>
            <Box sx={{ overflowX: 'auto', width: '100%', marginBottom: 2 }}>


              <Grid container spacing={1} padding={1} wrap="nowrap" width='93%'>
                {prompts && prompts.map((item: string, index: number) => (
                  <Grid item md={4} key={index}>
                    <Button
                      disabled={isLoading}
                      key={index}
                      variant="outlined"
                      sx={{
                        textTransform: 'none',
                        width: '100%',
                        height: '40px',
                        p: 1,
                      }}
                      onClick={() => {
                        const id = appStateContext?.state.currentChat?.id
                          ? appStateContext?.state.currentChat?.id
                          : undefined;
                        appStateContext?.state.isCosmosDBAvailable?.cosmosDB
                          ? makeApiRequestWithCosmosDB(item, id)
                          : makeApiRequestWithoutCosmosDB(item, id);
                      }}
                    >
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          textTransform: 'none',
                          color: (theme: any) => theme.palette.primary.main,
                          /* the size of the text */
                          fontSize: '12px',
                        }}
                      >
                        {item}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ width: '93%' }}>
                <QuestionInput
                  disabledButton={disabledButton()}
                  clearOnSend
                  placeholder="Type a new question..."
                  disabled={isLoading}
                  onSend={(question, id) => {
                    appStateContext?.state.isCosmosDBAvailable?.cosmosDB
                      ? makeApiRequestWithCosmosDB(question, id)
                      : makeApiRequestWithoutCosmosDB(question, id);
                  }}
                  onDeleteClick={newChat}
                  conversationId={
                    appStateContext?.state.currentChat?.id
                      ? appStateContext?.state.currentChat?.id
                      : undefined
                  }
                  showLoadingMessage={showLoadingMessage}
                  isLoading={isLoading}
                  onStopGeneration={stopGenerating}
                />

              </Box>
            </Box>
          </Box>
        </Box>
        {messages &&
          messages.length > 0 &&
          isCitationPanelOpen &&
          activeCitation && (
            <>
              <Dialog
                hidden={!isCitationPanelOpen}
                onClose={() => setIsCitationPanelOpen(false)}
                open={isCitationPanelOpen}
                fullWidth
                maxWidth="md"
                sx={{ p: 2 }}
              // dialogContentProps={{
              //   title: "Citations",
              //   // subText: activeCitation.title,
              // }}
              // modalProps={{
              //   isBlocking: true,
              //   styles: { main: { maxWidth: 450 } },
              // }}
              >
                <DialogTitle onClose={() => setIsCitationPanelOpen(false)}>
                  <Typography variant="h6" component="div">
                    Citations
                  </Typography>
                </DialogTitle>
                <Box sx={{ p: 2 }}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    children={activeCitation.content}
                    remarkPlugins={[remarkGfm]}
                    //@ts-ignore
                    rehypePlugins={[rehypeRaw]}
                  />
                </Box>
              </Dialog>
            </>
          )}
      </>

      <FeedbackDialog
        open={feedbackOpen}
        botAnswer={botAnswer}
        botId={botId}
        userMessage={userMessage}
        onClose={setFeedbackOpen}
        onSendFeedback={() => {
          setDisplaySendFeedbackMessage(true);
          setTimeout(() => {
            setDisplaySendFeedbackMessage(false);
          }, 3000);
        }}
      />
      {displaySendFeedbackMessage && (
        <Box
          sx={{
            position: 'fixed',
            top: '84px',
            left: '71px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 1000,
          }}
        >
          Feedback sent!
        </Box>
      )}
    </Box>
  );
};

export default Chat;

