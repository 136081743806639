import {
  UserInfo,
  ConversationRequest,
  Conversation,
  ChatMessage,
  CosmosDBHealth,
  CosmosDBStatus,
  Navigation,
} from './models';
import { authSettings, chatHistorySampleData } from '../../../data';
import { UserManager } from 'oidc-client';

export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal,
  convId?: string,
): Promise<Response> {
  const searchParams = new URLSearchParams();
  searchParams.append('streamFromAzureApi', 'true');
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/conversation?${searchParams}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
      body: JSON.stringify({
        conversation: {
          messages: options.messages,
          bot: options.bot,
        },
      }),
      signal: abortSignal,
    },
  );

  return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch('/.auth/me');
  if (!response.ok) {
    console.log('No identity provider found. Access to chat will be blocked.');
    return [];
  }

  const payload = await response.json();
  return payload;
}

export const fetchChatHistoryInit = async (
  id: number,
): Promise<Conversation[]> => {
  // export async function fetchChatHistoryInit(id:number): Conversation[] | null {
  console.log('fetchChatHistoryInit');
  // return chatHistorySampleData;
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const r = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/conversation/?botId=${id}`,
    {
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    }
  );
  const data = await r.json();
  return data;
};

export const fetchChatHistoryMessages = async (
  id: string,
): Promise<Conversation> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/conversation/${id}`,
    {
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    }
  );
  const payload = await response.json();
  return payload;
};

export const historyList = async (
  offset = 0,
): Promise<Conversation[] | null> => {
  const response = await fetch(`/history/list?offset=${offset}`, {
    method: 'GET',
  })
    .then(async (res) => {
      const payload = await res.json();
      if (!Array.isArray(payload)) {
        console.error('There was an issue fetching your data.');
        return null;
      }
      const conversations: Conversation[] = await Promise.all(
        payload.map(async (conv: any) => {
          let convMessages: ChatMessage[] = [];
          convMessages = await historyRead(conv.id)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              console.error('error fetching messages: ', err);
              return [];
            });
          const conversation: Conversation = {
            id: conv.id,
            title: conv.title,
            date: conv.createdAt,
            messages: convMessages,
          };
          return conversation;
        }),
      );
      return conversations;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return null;
    });

  return response;
};

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch('/history/read', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then(async (res) => {
      if (!res) {
        return [];
      }
      const payload = await res.json();
      let messages: ChatMessage[] = [];
      if (payload?.messages) {
        payload.messages.forEach((msg: any) => {
          const message: ChatMessage = {
            id: msg.id,
            role: msg.role,
            date: msg.createdAt,
            content: msg.content,
          };
          messages.push(message);
        });
      }
      return messages;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return [];
    });
  return response;
};

export const historyGenerate = async (
  options: ConversationRequest,
  abortSignal: AbortSignal,
  convId?: string,
): Promise<any> => {
  let body;
  // if (convId) {
  //   body = JSON.stringify({
  //     conversation_id: convId,
  //     messages: options.messages,
  //   });
  // } else {
  //   body = JSON.stringify({
  //     messages: options.messages,
  //   });
  // }
  // const staticResponse = {
  //   messages : options.messages,
  //   conversation_id: convId
  // }

  // const response = await fetch("/history/generate", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: body,
  //   signal: abortSignal,
  // })
  //   .then((res) => {
  //     return res;
  //   })
  //   .catch((err) => {
  //     console.error("There was an issue fetching your data.");
  //     return new Response();
  //   });
  // return response;
  // console.log('okkkkkkkkkkkkkkkkk');
  // return chatHistorySampleData;

  return conversationApi(options, abortSignal, convId);
};

export const historyUpdate = async (
  messages: ChatMessage[],
  convId: string,
  botId: string,
  title: string,
): Promise<Response> => {
  console.log(title);
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/conversation`,
    {
      method: 'POST',
      body: JSON.stringify({
        conversation: {
          id: convId,
          bot: { id: botId },
          title: title,
          messages: messages,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then(async (res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDelete = async (convId: string): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch('/history/delete', {
    method: 'DELETE',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyDeleteAll = async (): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch('/history/delete_all', {
    method: 'DELETE',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyClear = async (convId: string): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch('/history/clear', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyRename = async (
  convId: string,
  title: string,
): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch('/history/rename', {
    method: 'POST',
    body: JSON.stringify({
      conversation_id: convId,
      title: title,
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
  const response = await fetch('/history/ensure', {
    method: 'GET',
  })
    .then(async (res) => {
      let respJson = await res.json();
      let formattedResponse;
      if (respJson.message) {
        formattedResponse = CosmosDBStatus.Working;
      } else {
        if (res.status === 500) {
          formattedResponse = CosmosDBStatus.NotWorking;
        } else {
          formattedResponse = CosmosDBStatus.NotConfigured;
        }
      }
      if (!res.ok) {
        return {
          cosmosDB: false,
          status: formattedResponse,
        };
      } else {
        return {
          cosmosDB: true,
          status: formattedResponse,
        };
      }
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return {
        cosmosDB: false,
        status: err,
      };
    });
  return response;
};

export const frontendSettings = async (): Promise<Response | null> => {
  const response = await fetch('/frontend_settings', {
    method: 'GET',
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      return null;
    });

  return response;
};

export const fetchNavigation = async (): Promise<Navigation | null> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/navigationChatbot`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then(async (res) => {
      let data = await res.json().catch((e) => {
        throw e;
      });
      return data;
    })
    .catch((err) => {
      console.log({ err });
      console.error('There was an issue fetching your data.');
      return null;
    });

  return response;
};

export const addBot = async (bot: any): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/bots`, {
    method: 'POST',
    body: JSON.stringify(bot),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,

    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const deleteBot = async (botsIds: number[]): Promise<Response> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/bots/${botsIds.join(',')}`,
    {
      method: 'DELETE',
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const fetchRoles = async (): Promise<Response> => {
  console.log("in fetch roles");
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/sustainabilityChatbotRoles`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    }
  })
    .then((res) => {
      console.log(">>>res", res);
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const addRole = async (role: any): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/roles`, {
    method: 'POST',
    body: JSON.stringify({ role }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const deleteRoles = async (rolesIds: number[]): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  console.log(rolesIds);
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/roles/${rolesIds.join(',')}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error('Error deleting roles');
      }
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const fetchUsers = async (): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/users`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const deleteUser = async (usersIds: number[]): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/users/${usersIds.join(',')}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const updateUser = async (user: any): Promise<Response> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/users/${user.id}`,
    {
      method: 'PUT',
      body: JSON.stringify({ user }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const postFeedback = async (feedback: any): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/feedbacks`,
    {
      method: 'POST',
      body: JSON.stringify({ feedback }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const fetchFeedbacks = async (): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/feedbacks`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};

export const deleteFeedback = async (
  feedbacksIds: number[],
): Promise<Response> => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  const response = await fetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/api/feedbacks/${feedbacksIds.join(',')}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user?.access_token}`,
      },
    },
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error('There was an issue fetching your data.');
      let errRes: Response = {
        ...new Response(),
        ok: false,
        status: 500,
      };
      return errRes;
    });
  return response;
};
