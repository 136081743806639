import { useContext, useState } from 'react';
import { Divider, IconButton, Tooltip } from '@mui/material';
import { TextField, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import { grey } from '@mui/material/colors';
import StopIcon from '@mui/icons-material/Stop';
import HistoryIcon from '@mui/icons-material/History';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import ChatHistory from '../../Sustainability/layout/ChatHistory';
import { AppStateContext } from '../State/AppProvider';
interface Props {
  onSend: (question: string, id?: string) => void;
  onDeleteClick: () => void;
  onStopGeneration: () => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId?: string;
  disabledButton?: boolean;
  showLoadingMessage?: boolean;
  isLoading?: boolean;
  displayChatHistoryButton?: boolean;
  
}

export const QuestionInput = ({
  onSend,
  onDeleteClick,
  onStopGeneration,
  disabled,
  placeholder,
  clearOnSend,
  conversationId,
  disabledButton,
  showLoadingMessage,
  isLoading,
  displayChatHistoryButton = true,
}: Props) => {
  const [question, setQuestion] = useState<string>('');
  const [openChatHistory, setOpenChatHistory] = useState(false);
  const appStateContext = useContext(AppStateContext);


  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    if (conversationId) {
      onSend(question, conversationId);
    } else {
      onSend(question);
    }

    if (clearOnSend) {
      setQuestion('');
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (_ev: any) => {
    setQuestion(_ev.target.value || '');
  };

  const sendQuestionDisabled = disabled || !question.trim();

  const onChatHistoryClick=() => {
    setOpenChatHistory(true);
  }
  const onChatHistoryDelete=() => {
    appStateContext?.dispatch({
      type: 'DELETE_CHAT_HISTORY',
    });
  }

  return (
    <>
    <Stack
      direction="row"
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ p: 1, pt: 0, h: '80px', backgroundColor: 'white' }}
    >
      {disabledButton ? (
        <IconButton
          sx={{
            position: 'relative',
            w: '50px',
            h: '50px',
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={onStopGeneration}
          disabled={!isLoading}
        >
          <Tooltip title="Stop generating" arrow placement="top">
            <StopIcon />
          </Tooltip>
        </IconButton>
      ) : null}
      {!disabledButton ? (
        <IconButton
          sx={{
            position: 'relative',
            w: '50px',
            h: '50px',
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={onDeleteClick}
        >
          <Tooltip title="Delete Messages" arrow placement="top">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      ) : null}

      <TextField
        variant="filled"
        fullWidth
        disabled={disabled}
        placeholder={placeholder}
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
        autoComplete="off"
      />
      <IconButton
        sx={{
          position: 'relative',
          w: '50px',
          h: '50px',
          color: (theme) => theme.palette.primary.main,
        }}
        onClick={sendQuestion}
        onKeyDown={(e) =>
          e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null
        }
        disabled={sendQuestionDisabled}
      >
        {showLoadingMessage}
        {showLoadingMessage ? (
          <SmsIcon
            sx={{
              color: grey[500],
              width: '20px',
              height: '20px',
              animation: 'fadeInOut 2s infinite',
            }}
          />
        ) : (
          <SendIcon />
        )}
      </IconButton>

{ 
  displayChatHistoryButton &&     <>
  <Divider orientation="vertical" flexItem />
     {/* {(appStateContext?.state?.chatHistory || []).length === 0 ? null : ( */}

      <IconButton
          sx={{
            position: 'relative',
            w: '50px',
            h: '50px',
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={onChatHistoryClick}
        >
          <Tooltip title="Open chat History" arrow placement="top">
            <HistoryIcon />
          </Tooltip>
        </IconButton>
        {/* )} */}

        {/* {(appStateContext?.state?.chatHistory || []).length === 0 ? null : ( */}

      <IconButton
          sx={{
            position: 'relative',
            w: '50px',
            h: '50px',
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={() =>{ 
            // handleCloseUserMenu();
            onChatHistoryDelete();}}
        >
          <Tooltip title="Delete chat History" arrow placement="top">
            <AutoDeleteIcon />
          </Tooltip>
        </IconButton>
        </>
}
{/* )} */}

        {/* {(appStateContext?.state?.chatHistory || []).length === 0 ? null : (
            <MenuItem onClick={handleCloseUserMenu}>
              <Stack direction="row" spacing={2} onClick={onChatHistoryClick}>
                <HistoryIcon />
                <Typography textAlign="center">Open chat history</Typography>
              </Stack>
            </MenuItem>
          )}
          {(appStateContext?.state?.chatHistory || []).length === 0 ? null : (
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                onChatHistoryDelete();
              }}
            >
              <Stack direction="row" spacing={2}>
                <Delete />
                <Typography textAlign="center">Delete chat history</Typography>
              </Stack>
            </MenuItem>
          )} */}
    </Stack>
    <ChatHistory open={openChatHistory} onMenuOpen={setOpenChatHistory} />
    </>
  );
};
