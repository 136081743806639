import * as React from 'react';
import { useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BotManagement from './BotManagement';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from '@mui/material';
import RoleManagement from './RoleManagement';
import UserManagement from './UserManagement';
import UserFeedback from './UserFeedback';
import Guard, { Role } from '../../SustainabilityChatbot/Common/Guard';
import Layout from '../../Layout/Layout';
export default function TabsWrappedLabel() {
  const [value, setValue] = React.useState('botConfig');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Layout noBreadcrumbs>
    <Box
      sx={{
        pt: 10,
        ml: (theme) => `calc(${theme.spacing(10)} + 1px)`,
      }}
    >
      <Guard role={Role.Admin} message>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab value="botConfig" label="Bot configuration" />
          <Tab value="rolemanagement" label="Role management" />
          {/* <Tab value="userManagement" label="User Management" /> */}
          <Tab value="feedback" label="User Feedback" />
        </Tabs>
        {value === 'botConfig' ? (
          <BotManagement />
        // ) : value === 'userManagement' ? (
        //   <UserManagement />
        ) : value === 'rolemanagement' ? (
          <RoleManagement />
        ) : value === 'feedback' ? (
          <UserFeedback />
        ) : null}
      </Guard>
    </Box>
    </Layout>
  );
}
