import { useEffect, useState } from "react"
import { Box, CardActionArea, Divider, Grid, ListItemIcon, Paper, Skeleton, SortDirection, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { createSearchParams, useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { visuallyHidden } from '@mui/utils';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SustainabilityDetail from "./SustainabilityDetail";
import apiClient from "../../services/apiClient"
import { useNav } from "../NavProvider";
import InsightsIcon from '@mui/icons-material/Insights';
import SustainabilityIcon from "../shared/Icons/SustainabilityIcon";


interface HeadCell {
  name: string;
  label: string;
  sortBy?: string;
}

function StyledTableCell({ row }: any) {
  const value = row?.yoyChange;
  const isPositive = value > 0;

  return (
    <TableCell >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          noWrap
          sx={{
            maxWidth: 80,
            fontWeight: 'bold',
            color: isPositive ? 'green' : 'red',
            marginRight: 2,
            fontSize: 18,
          }}
        >
          {isPositive ? '+' + value : value}
        </Typography>

      </Box>
    </TableCell>
  );
}


const RowWithCircle = ({ props }: any) => (
  <TableCell sx={{ px: 0.5, display: 'flex', alignItems: 'center' }}>
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: 'white', // White background
        border: '1px solid black', // Black border
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        mr: 1,
      }}
    >
      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
        {(props.index) + 1}
      </Typography>
    </Box>
    <Typography noWrap sx={{ maxWidth: 200, fontSize: 18 }}>
      {props.row?.supplier.name}
    </Typography>
  </TableCell>
);

function Row(props: {
  row: any;
  supplierId: string | undefined;
  index: number;
  handleRowClick: any;
}) {

  return (
    <>
      <TableRow
        key={props.row?.id}
        onClick={() => {
          props.handleRowClick();
        }}
        sx={{ cursor: 'pointer' }}
        style={{ position: 'relative' }}
      >
        <RowWithCircle props={props} />
        <TableCell sx={{ px: 0.5 }}>
          <Typography noWrap sx={{ maxWidth: 80, fontSize: 18 }}>
            {props.row?.score}
          </Typography>
        </TableCell>

        <StyledTableCell row={props.row}></StyledTableCell>
        <TableCell >
          <Typography noWrap sx={{ fontSize: 18, color: "#e41b13", fontWeight: 'bold' }}>
            See details
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}


export default function Benchmark() {

  const [suppliers, setSuppliers] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const perPage = Number(searchParams.get('perPage') || 50);
  const orderBy = searchParams.get('sortBy') || 'score';
  const order: SortDirection =
    searchParams.get('order') === 'asc' ? 'asc' : 'desc';
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const { setIsFrozen } = useNav();
  const pathPrefix = '/domains';
  const match = useMatch(`${pathPrefix}/:tab/:id`);
  const [supplierId, setSupplierId] = useState<any>(Number(match?.params.id) || null);
  const navigate = useNavigate();

  const headCells: HeadCell[] = [
    { name: 'supplierId', label: 'Supplier' },
    { name: 'score', label: 'Sustainability score', sortBy: 'score' },
    { name: 'yoyChange', label: 'Year Change' },
    { name: 'kpi', label: '' }
  ];

  useEffect(() => {
    if (!isClicked) {
      setSuppliers(null);
      setPage(1);
    }
    apiClient
      .get(`/sustainabilityScores`, {
        params: {
          page: page, perPage: perPage, sortBy: orderBy,
          order: order.toUpperCase(),
        },
      })
      .then(({ data }) => {
        if (isClicked) {
          data.records = (suppliers?.records || []).concat(
            data.records,
          );
        }
        setSuppliers(data);
        setLoading(false);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsClicked(false);
        setLoading(false);
      });
  }, [page, perPage, orderBy, order])

  const handleSortChange = (property: string) => {
    searchParams.set('sortBy', property);
    searchParams.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(searchParams);
  };

  const SkeletonRow = () => (
    <TableRow>
      {Array(3)
        .fill(null)
        .map((v, i) => (
          <TableCell key={i} sx={{ px: 0.5 }}>
            <Skeleton width={80} />
          </TableCell>
        ))}
    </TableRow>
  );

  return (
    <>
      {suppliers?.records?.length !== 0 &&
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TableContainer component="div" sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Table aria-label="collapsible table" sx={{ width: '100%', mt: 1, border: '1px solid #D3D3D3', marginLeft: 5 }}>
              <TableHead
                sx={(theme) => ({ backgroundColor: theme.palette.primary.main, })}
              >
                <TableRow>
                  {headCells.map((cell, index) => (
                    <TableCell
                      key={cell.name}
                      onClick={() => cell.sortBy && handleSortChange(cell.sortBy)}
                      sortDirection={orderBy === cell.sortBy ? order : false}
                      sx={{
                        px: 1,
                        color: "#FFF",
                        fontSize: 18,
                        py: 1,
                      }}
                    >
                      {cell.sortBy ? (
                        <TableSortLabel
                          sx={{
                            color: "#FFF!important",
                            fontSize: 18,
                          }}
                          active={orderBy === cell.sortBy}
                          direction={orderBy === cell.sortBy ? order : 'asc'}
                          onClick={() =>
                            cell.sortBy && handleSortChange(cell.sortBy)
                          }
                        >
                          {cell.label}
                          {orderBy === cell.sortBy ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        cell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array(5)
                    .fill(null)
                    .map((v, i) => <SkeletonRow key={i} />)
                  : <>
                    {suppliers?.records.map((row: any, index: number) => (
                      <>
                        <Row
                          key={row.id}
                          row={row}
                          supplierId={row.supplier.id}
                          index={index}
                          handleRowClick={() => {
                            setIsFrozen(true);
                            navigate(
                              `${pathPrefix}/sustainability/${row.supplier.id}`
                            );
                            setSupplierId(row.supplier.id);
                          }
                          }
                        />

                      </>
                    ))}
                  </>
                }
                {supplierId && (
                  <SustainabilityDetail
                    handleSustainabilityDialogClose={() => {
                      const newSearchParams = createSearchParams();
                      navigate({
                        pathname: `${pathPrefix}/sustainability`,
                        search: newSearchParams.toString(),
                      });
                      setIsFrozen(false);
                      setSupplierId(null)
                    }}
                    supplierId={Number(supplierId)}
                  />
                )}
              </TableBody>
            </Table>

            <Box sx={{ border: '2px solid #e41b13', p: 2, mr: 2, mt: 12, ml: 4, width: 200 }}>
              <Box>
                <Typography variant="body1"><strong>Average:</strong> {suppliers?.averageScore}</Typography>
                <Typography variant="body1"><strong>Median:</strong> {suppliers?.medianScore}</Typography>
              </Box>
            </Box>
          </TableContainer>

        </Box>
      }
      {suppliers?.records?.length === 0 && <Typography style={{ marginTop: 2 }}>
        No Sustainability Scores data
      </Typography>}
      {suppliers?.records?.length !== 0 &&
        suppliers?.records?.length < suppliers?.pagination?.total && (
          <>
            <Divider />
            <CardActionArea
              sx={{ p: 1, px: 2 }}
              onClick={() => {
                setLoading(true);
                setIsClicked(true);
                setPage(page + 1);
              }}
            >
              <Stack direction="row" spacing={1} p={1} justifyContent="flex-end">
                <ManageSearchIcon color="primary" />
                <Typography color="primary" style={{ marginTop: 2 }}>
                  Show more Suppliers
                </Typography>
              </Stack>
            </CardActionArea>
            <Divider />
          </>
        )}
    </>
  )



}