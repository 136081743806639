import React, { useContext, useEffect } from 'react';
import {
  Avatar,
  Button,
  Chip,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { UserManager } from 'oidc-client';
import { DataGrid } from '@mui/x-data-grid';
import { AppStateContext } from '../../SustainabilityChatbot/State/AppProvider';
import MuiIconPicker from './MuiIconPicker';
import { IconComponent } from './MuiIconPicker';
import ConfirmDialog from './ConfirmDialog/ConfirmDialog';
import { authSettings } from '../../../data';

export default function BotManagement() {
  const appStateContext = useContext(AppStateContext);
  const validationSchema = yup.object().shape({
    name: yup.string().required('The Bot Name is Required'),
    prompt1: yup.string().required('The Bot Prompt 1 is Required'),
    prompt2: yup.string().required('The Bot Prompt 2 is Required'),
    prompt3: yup.string().required('The Bot Prompt 3 is Required'),
    navigationIconName: yup.string().required('The Bot Icon is Required'),
    systemMessage: yup.string().required('The Bot System Message is Required'),
    sustainabilityChatbotRoles: yup.array().required('Please select at least one role'),
    temperature: yup
      .number()
      .required('The Bot temperature is Required')
      .min(0, 'The Bot temperature must be greater than 0')
      .max(1, 'The Bot temperature must be less than 1'),
  });
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const handleChange = ({ target }: any) => {
    setFile(target.files[0]);
    setUploadStep(uploadSteps.uploadAndSave);
  };

  const [file, setFile] = React.useState<File | null>(null);
  const uploadSteps = {
    choose: 'Choose logo...',
    uploadAndSave: 'Upload and Save',
    uploading: 'Uploading...',
    uploaded: 'Uploaded',
  };
  const [uploadStep, setUploadStep] = React.useState(uploadSteps.choose);
  
  const queryTypes = [
    {
      value: 'simple',
      label: 'Simple',
    },
    {
      value: 'vectorSimpleHybrid',
      label: 'Vector Simple Hybrid',
    },
    {
      value: 'vectorSemanticHybrid',
      label: 'Vector Semantic Hybrid',
    },
  ];

  const [openConfirm, setOpenConfirm] = React.useState(false);

  useEffect(() => {
    const handleEscape = (e: any) => {
      if (
        e.key === 'Escape' &&
        uploadStep !== uploadSteps.uploading &&
        uploadStep !== uploadSteps.uploaded
      ) {
        setFile(null);
        setUploadStep(uploadSteps.choose);
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [file, uploadStep]);

  const initialValues = {
    name: '',
    prompt1: '',
    prompt2: '',
    prompt3: '',
    navigationIconName: null,
    systemMessage: '',
    index: '',
    sustainabilityChatbotRoles: [appStateContext?.state?.sustainabilityChatbotRoles.map((role: any) => role.id)[0]] || [],
    securityGroupsIds: '',
    semanticConfiguration: null,
    queryType: queryTypes[0].value,
    temperature: 0.5,
  };

  useEffect(() => {

    appStateContext?.dispatch({
      type: 'CHANGE_PAGE',
      payload: 'admin/botManagement',
    });
  }, []);

  const processRowUpdate = async (newRow: any) => {
    const userManager = new UserManager(authSettings);
    const user = await userManager.getUser();
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/api/bots/${newRow.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.access_token}`,
        },
        body: JSON.stringify({
          bot: {
            ...newRow,
            prompts: [newRow.prompt1, newRow.prompt2, newRow.prompt3],
            name: newRow.label,
          },
        }),
      }).then(async (response) => {
        if (response.ok) {
          const r = await response.json();
          appStateContext?.dispatch({
            type: 'ADD_BOT',
            payload: r,
          });
          resolve(r);
        } else {
          reject(new Error('Error'));
        }
      });
    });
  }

 //appStateContext?.state?.sustainabilityChatbotRoles?.length === 0 ? null : 
  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(
          form,
          { resetForm }: { setSubmitting: any; resetForm: any },
        ) => {
          console.log("submit form", form.name);
          const groupsIds = (form.securityGroupsIds || '')
            .split(',')
            .filter((group: any) => group);
          appStateContext?.dispatch({
            type: 'ADD_BOT',
            payload: {
              ...form,
              prompt1: undefined,
              prompt2: undefined,
              prompt3: undefined,
              prompts: [form.prompt1, form.prompt2, form.prompt3],
              sustainabilityChatbotRoles: form.sustainabilityChatbotRoles.map((role: any) => ({ id: role })),
              groupsIds: groupsIds?.length ? groupsIds : null,
              semanticConfiguration:
                form.queryType === 'simple' ||
                form.queryType === 'vectorSimpleHybrid'
                  ? null
                  : form.semanticConfiguration,
            },
          });
          resetForm();
        }}
      >
        {({
          errors,
          touched,
          setTouched,
          isSubmitting,
          values,
          handleSubmit,
          submitForm,
        }) => (
          <Form>
            <Grid container spacing={2} p={1}>
              <Stack
                direction="row"
                spacing={2}
                p={1}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting}
                >
                  Add
                </Button>
              </Stack>

              <Grid xs={12} item>
                <Field name="name">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Name"
                      error={Boolean(errors['name'] && touched['name'])}
                      helperText={Boolean(touched['name']) && errors['name']}
                    ></TextField>
                  )}
                </Field>
              </Grid>

              <Grid xs={6} item>
                <Field name="prompt1">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Prompt 1"
                      error={Boolean(errors['prompt1'] && touched['prompt1'])}
                      helperText={
                        Boolean(touched['prompt1']) && errors['prompt1']
                      }
                    ></TextField>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="prompt2">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Prompt 2"
                      error={Boolean(errors['prompt2'] && touched['prompt2'])}
                      helperText={
                        Boolean(touched['prompt2']) && errors['prompt2']
                      }
                    ></TextField>
                  )}
                </Field>
              </Grid>
              <Grid xs={12} item>
                <Field name="prompt3">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Prompt 3"
                      error={Boolean(errors['prompt3'] && touched['prompt3'])}
                      helperText={
                        Boolean(touched['prompt3']) && errors['prompt3']
                      }
                    ></TextField>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="systemMessage">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="System Message"
                      error={Boolean(
                        errors['systemMessage'] && touched['systemMessage'],
                      )}
                      helperText={
                        Boolean(touched['systemMessage']) &&
                        errors['systemMessage']
                      }
                    ></TextField>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="index">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Index"
                      error={Boolean(errors['index'] && touched['index'])}
                      helperText={Boolean(touched['index']) && errors['index']}
                    ></TextField>
                  )}
                </Field>
              </Grid>

              <Grid xs={6} item>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="logo"
                  multiple
                  type="file"
                  onChange={handleChange}
                  onClick={async(e: any) => {
                    const { target } = e;
                    target.value = null;

                    if (uploadStep === uploadSteps.uploadAndSave && file) {
                      e.preventDefault();
                      setUploadStep(uploadSteps.uploading);
                      const formData = new FormData();
                      formData.append('logo', file);
                      formData.append('name', values.name);
                      const userManager = new UserManager(authSettings);
                      const user = await userManager.getUser();
                      const path = `${
                        process.env.REACT_APP_API_ENTRYPOINT
                      }/api/logos/upload`;
                      fetch(path, {
                        method: 'POST',
                        headers: {
                          Accept: 'application/json',
                          Authorization: `Bearer ${user?.access_token}`,

                        },
                        body: formData,
                      })
                        .then((data) => {
                          setUploadStep(uploadSteps.uploaded);
                          setTimeout(() => {
                            setUploadStep(uploadSteps.choose);
                            setFile(null);
                          }, 3000);
                        })
                        .catch((error) => {
                          console.error('Error:', error);
                          setUploadStep(uploadSteps.choose);
                          setFile(null);
                        });
                    }
                  }}
                />
                <Button
                  component="label"
                  variant="outlined"
                  htmlFor={'logo'}
                  fullWidth
                  sx={{
                    backgroundColor: 'grey.100',
                    borderColor: 'grey.500',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    height: '100%',
                  }}
                  disabled={
                    uploadStep === uploadSteps.uploading ||
                    uploadStep === uploadSteps.uploaded ||
                    values.name === ''
                  }
                >
                  {`${uploadStep}${file ? ' (1)' : ''}`}
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Field name="navigationIconName">
                  {({ field }: { field: any }) => (
                    <MuiIconPicker
                      {...field}
                      onSelect={(icon) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: icon,
                          },
                        });
                      }}
                      fullWidth
                      onChoose={() => {
                        setTouched({ navigationIconName: true });
                      }}
                      error={Boolean(
                        errors['navigationIconName'] &&
                          touched['navigationIconName'],
                      )}
                      helperText={
                        Boolean(touched['navigationIconName']) &&
                        errors['navigationIconName']
                      }
                    />
                  )}
                </Field>
              </Grid>

              <Grid xs={6} item>
                <Field name="sustainabilityChatbotRoles">
                  {({ field }: { field: any }) => (
                    <Select
                      {...field}
                      fullWidth
                      labelId="demo-multiple-chip-label"
                      input={
                        <OutlinedInput id="select-multiple-chip" label="Chip" />
                      }
                      renderValue={(values: any) =>
                        values.map((value: any, index: number) => (
                          <Chip
                            key={`role-${index}`}
                            size="small"
                            label={
                              appStateContext?.state?.sustainabilityChatbotRoles.find(
                                (role: any) => role.id === value,
                              )?.name
                            }
                            variant="filled"
                          />
                        ))
                      }
                      multiple
                    >
                      {appStateContext?.state?.sustainabilityChatbotRoles.map((role: any) => (
                        <MenuItem key={role.id} value={role.id}>
                          <Chip
                            key={role.id}
                            size="medium"
                            label={role.name}
                            variant="outlined"
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="securityGroupsIds">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Security Groups Ids (comma separated)"
                    ></TextField>
                  )}
                </Field>
              </Grid>
              <Grid xs={12} item>
                <Field name="semanticConfiguration">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Semantic Configuration"
                      disabled={
                        values.queryType === 'simple' ||
                        values.queryType === 'vectorSimpleHybrid'
                      }
                      value={
                        values.queryType === 'simple' ||
                        values.queryType === 'vectorSimpleHybrid'
                          ? ''
                          : field.value
                      }
                    ></TextField>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="queryType">
                  {({ field }: { field: any }) => (
                    <Select
                      {...field}
                      fullWidth
                      variant="filled"
                      label="Query Type"
                    >
                      {queryTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
              </Grid>
              <Grid xs={6} item>
                <Field name="temperature">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      label="temperature"
                      error={Boolean(
                        errors['temperature'] && touched['temperature'],
                      )}
                      helperText={
                        Boolean(touched['temperature']) && errors['temperature']
                      }
                    ></TextField>
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container spacing={2} p={1}>
        <Grid xs={3} item p={1}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenConfirm(true);
            }}
            disabled={selectedIds.length === 0}
          >
            Delete Selection
          </Button>
        </Grid>
      </Grid>

      <DataGrid
       onSelectionModelChange={(ids: any) => {
        setSelectedIds(ids);
      }}
        rows={appStateContext?.state?.navigation || []}
        columns={[
          { field: 'id', headerName: 'ID', width: 70 },
          { field: 'label', headerName: 'Name', width: 130, editable: true },
          {
            field: 'prompt1',
            headerName: 'Prompt 1',
            width: 130,
            editable: true,
          },
          {
            field: 'prompt2',
            headerName: 'Prompt 2',
            width: 130,
            editable: true,
          },
          {
            field: 'prompt3',
            headerName: 'Prompt 3',
            width: 130,
            editable: true,
          },
          {
            field: 'navigationIconName',
            headerName: 'Icon',
            width: 130,
            editable: true,
            renderCell: (params: any) => (
              <Chip
                size="medium"
                avatar={
                  <Avatar
                    children={<IconComponent iconName={params.value} />}
                  />
                }
                label={params.value}
                variant="filled"
              />
            ),
            renderEditCell: (params: any) => (
              <MuiIconPicker
                onSelect={(icon) => {
                  params.api.setEditCellValue({
                    id: params.id,
                    field: params.field,
                    value: icon,
                  });
                }}
              />
            ),
          },
          {
            field: 'systemMessage',
            headerName: 'System Message',
            width: 130,
            editable: true,
          },
          { field: 'index', headerName: 'Index', width: 130, editable: true },
          {
            field: 'groupsIds',
            headerName: 'Security Groups Ids',
            width: 130,
            editable: true,
            renderCell: (params: any) =>
              !params.value?.length ? (
                <Tooltip title="No Security Groups" arrow>
                  <Chip
                    size="small"
                    label="None"
                    variant="filled"
                    sx={{ margin: 0.25 }}
                  />
                </Tooltip>
              ) : (
                <></>
                // <Tooltip title={(params?.value || []).join(', ')} arrow>
                //   {params.value?.map((group: any) => (
                //     <Chip
                //       size="small"
                //       label={group}
                //       variant="filled"
                //       sx={{ margin: 0.25 }}
                //     />
                //   ))}
                // </Tooltip>
              ),
            renderEditCell: (params: any) => (
              <TextField
                value={params.value}
                onChange={(e) => {
                  params.api.setEditCellValue({
                    id: params.id,
                    field: params.field, 
                    value: e.target.value?.split(',').filter((group: any) => group),
                  });
                }}
              />
            ),
          },
          {
            field: 'semanticConfiguration',
            headerName: 'Semantic Configuration',
            editable: true,
          },
          {
            field: 'queryType',
            headerName: 'Query Type',
            editable: true,
          },
          {
            field: 'temperature',
            headerName: 'Temperature',
            editable: true,
          },
        ]}
        checkboxSelection
        autoHeight
        processRowUpdate={async (newRow: any) => {
          await processRowUpdate(newRow);
          return {
            ...newRow,
          };
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
      <ConfirmDialog
        title="Confirm deleting Bots"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={() => {
          appStateContext?.dispatch({
            type: 'DELETE_BOT',
            payload: selectedIds,
          });
          setSelectedIds([]);
        }}
      >
        <Typography>
          This action will delete also all the conversations associated with the selected Bots. Are you sure you want to proceed?
        </Typography>
      </ConfirmDialog>
    </>
  );
}
