import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import * as Icons from '@mui/icons-material';

import DialogTitle from './UserEditDialog/DialogTitle';
export type IconNames = keyof typeof Icons
export type IconProps = {
  iconName: IconNames
}

export const IconComponent: React.FC<IconProps> = ({
  iconName,
}:{
  iconName: IconNames
}) => {
  const Icon = Icons[iconName]
  return <Icon />
}


export default function MuiIconPicker({
  onSelect,
  onChoose,
  error,
  helperText,
}: {
  onSelect: (icon: IconNames) => void;
  onChoose?: () => void;
  error?: string;
  helperText?: string;
}) {
  const [search, setSearch] = React.useState<IconNames | ''>('');
  const [open, setOpen] = React.useState(false);
  const IconTypes = ['Filled', 'Outlined', 'Rounded', 'TwoTone', 'Sharp'];
  const [iconType, setIconType] = React.useState('Filled');
  const [selectedIon, setSelectedIcon] = React.useState<IconNames | ''>('');
  return (
    <Box>
      <Button
        onClick={() => {
          setOpen(true);
          onChoose?.();
        }}
        fullWidth
      >
        {selectedIon ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconComponent iconName={selectedIon} />
            <Typography>{selectedIon}</Typography>
          </Stack>
        ) : (
          'Choose Icon'
        )}
      </Button>
      <Typography variant="caption" color={error ? 'error' : 'textSecondary'}>
        {error && helperText}
      </Typography>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle onClose={() => setOpen(false)}>Choose Icon</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}
              >
                {IconTypes.map((type) => (
                  <Button
                    key={type}
                    onClick={() => setIconType(type)}
                    variant={iconType === type ? 'contained' : 'outlined'}
                  >
                    {type}
                  </Button>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={9}>
              <TextField
                label="Search Icon"
                value={search}
                onChange={(e) => setSearch(e.target.value as IconNames)}
                fullWidth
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: 'white',
                }}
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    backgroundColor: 'white',
                    position: 'sticky',
                    top: -20,
                    width: '100%',
                    height: 20,
                    zIndex: 1,
                  }}
                ></Grid>
                {Object.keys(Icons)
                  .filter((icon) => {
                    const isIconTypeFilled = iconType === 'Filled';
                    const iconLower = icon.toLocaleLowerCase();
                    const iconTypeLower = iconType.toLocaleLowerCase();
                    const isIconEndsWithIconType =
                      iconLower.endsWith(iconTypeLower);
                    const isIconNotSpecial =
                      !icon.includes('Outlined') &&
                      !icon.includes('Rounded') &&
                      !icon.includes('TwoTone') &&
                      !icon.includes('Sharp');
                    const isIconLowerIncludesSearchLower = iconLower.includes(
                      search?.toLocaleLowerCase(),
                    );
                    if (!search) {
                      return isIconTypeFilled
                        ? isIconNotSpecial
                        : isIconEndsWithIconType;
                    }
                    return (
                      isIconLowerIncludesSearchLower &&
                      (isIconTypeFilled
                        ? isIconNotSpecial
                        : isIconEndsWithIconType)
                    );
                  })
                  .map((icon) => (
                    <Grid item key={icon} xs={3}>
                      <Stack direction="column" spacing={1} alignItems="center">
                        <IconButton
                          onClick={() => {
                            setSelectedIcon(icon as IconNames);
                            setOpen(false);
                            onSelect(icon as IconNames);
                          }}
                          sx={{
                            p: 1,
                            bgcolor: 'grey.100',
                            '&:hover': {
                              bgcolor: 'grey.200',
                            },
                          }}
                        >
                          <IconComponent iconName={icon as IconNames} />
                        </IconButton>

                        <Typography
                          variant="caption"
                          sx={{
                            textTransform: 'capitalize',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            color: 'grey.400',
                          }}
                        >
                          {icon}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
