import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router';
import { AppStateContext } from '../../SustainabilityChatbot/State/AppProvider';
import {
  fetchChatHistoryInit,
  fetchChatHistoryMessages,
} from '../../SustainabilityChatbot/api/';

export const drawerWidth = 500;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
  overflowX: 'hidden',
  backgroundColor: 'rgba(255,255,255,0.8)',
  marginTop: 70,
  height:  'calc(100% - 170px)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'hidden',
  width: `0px`,
  marginTop: 70,
  height: 'calc(100% - 170px)',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: theme.zIndex.drawer,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      scrollbarWidth: 'thin',
      scrollbarColor: 'gray',
      '&::-webkit-scrollbar': {
        width: '7px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(255,255,255,0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray',
        borderRadius: '10px',
        border: `none`,
      },
    },
  }),
  ...(!open && {
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
export const icons = [
  {
    id: 1,
    Icon: <SmartToyIcon />,
    name: 'Smart Toy',
  },
  {
    id: 2,
    Icon: <LocalPoliceIcon />,
    name: 'Local Police',
  },
];

export default function ChatHistory({
  open,
  onMenuOpen,
}: {
  open: boolean;
  onMenuOpen: (open: boolean) => void;
}) {
  const appStateContext = useContext(AppStateContext);
  const location = useLocation();

  useEffect(() => {
    if (
      !appStateContext?.state.pageName &&
      (appStateContext?.state.navigation || []).length > 0 &&
      location.pathname !== '/'
    ) {
      appStateContext?.dispatch({
        type: 'CHANGE_PAGE',
        payload: location.pathname.replace('/', ''),
      });
      return;
    }

    const id = (appStateContext?.state?.navigation || []).find(
      (n) => n.path === appStateContext?.state.pageName,
    )?.id;

    if (!id) return;

    const fetchChatHistory = async () => {
      const response = await fetchChatHistoryInit(id);
      appStateContext?.dispatch({
        type: 'FETCH_CHAT_HISTORY',
        payload: response,
      });
    };
    fetchChatHistory();
  }, [appStateContext?.state.pageName]);

  return (
    <>
      <Drawer anchor="right" variant="permanent" open={open}>
        {appStateContext?.state?.chatHistory?.map(
          (navItem: any, index: number) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.25,
                }}
                onClick={async () => {
                  const conv = await fetchChatHistoryMessages(navItem.id);
                  appStateContext?.dispatch({
                    type: 'UPDATE_CURRENT_CHAT',
                    payload: conv,
                  });
                  onMenuOpen(false);
                }}
              >
                <ListItemText
                  primary={navItem.title}
                  secondary={new Date(navItem.date).toLocaleString()}
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ),
        )}

        <Divider />
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.25,
            }}
            onClick={() => {
              onMenuOpen(false);
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <CloseIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Close'}
              sx={{
                opacity: open ? 1 : 0,
              }}
            />
          </ListItemButton>
        </ListItem>
      </Drawer>
    </>
  );
}
