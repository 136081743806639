import React, { useContext, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { AppStateContext } from '../../SustainabilityChatbot/State/AppProvider';
import DialogTitle from '../../admin/SustainabilityChatbotManager/UserEditDialog/DialogTitle';
import { reaosons } from '../../admin/SustainabilityChatbotManager/UserFeedback';

export default function FeedbackDialog({
  open,
  botId,
  botAnswer,
  userMessage,
  onClose,
  onSendFeedback,
}: {
  open: boolean;
  botAnswer: string;
  botId: number;
  userMessage: string;
  onClose: (open: boolean) => void;
  onSendFeedback: () => void;
}) {
  const appStateContext = useContext(AppStateContext);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>();

  const handleCancel = () => {
    onClose(false);
  };

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (value === 5) {
      //uncheck all other reasons
      newChecked.splice(0, newChecked.length);

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);

      return;
    }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked.filter((c) => c !== 5));
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle onClose={handleCancel}>Send feedback</DialogTitle>
      <DialogContent>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {reaosons.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;

            return (
              <ListItem key={value.id} disablePadding sx={{ width: '100%' }}>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value.name}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <TextField
          autoFocus
          margin="dense"
          id="feedback"
          label="Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={checked.filter((c) => c === 5).length === 0}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={async () => {
            setSubmitting(true);
            const payload = {
              feedback: value,
              reasons: checked,
              isDisliked: true,
              isLiked: false,
              userMessage: userMessage,
              botAnswer: `${botAnswer}`,
              bot: { id: botId }
            };
            appStateContext?.dispatch({
              type: 'POST_FEEDBACK',
              payload: payload,
            });

            onSendFeedback();
            onClose(false);
          }}
          disabled={
            // checked.length === 0 ||
            // (checked.filter((c) => c === 5).length === 1 && value === "")
            checked.filter((c) => c === 5).length === 1 && value === ''
          }
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
