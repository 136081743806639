import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../SustainabilityChatbot/State/AppProvider';
import {
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const reaosons = [
  {
    id: 1,
    name: 'Citations are missing',
  },
  {
    id: 2,
    name: 'Citations are wrong',
  },
  {
    id: 3,
    name: 'The response is not from my data',
  },
  {
    id: 4,
    name: 'Inaccuarate or irrelevant',
  },
  {
    id: 5,
    name: 'Other',
  },
];

export default function UserFeedback() {
  const appStateContext = useContext(AppStateContext);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const summary = {
    totalLikes: appStateContext?.state.feedbacks.filter(
      (feedback: any) => feedback.isLiked,
    ).length,
    totalDislikes: appStateContext?.state.feedbacks.filter(
      (feedback: any) => feedback.isDisliked,
    ).length,
    totalFeedbacks: appStateContext?.state.feedbacks.length,
    totalUsers: appStateContext?.state.users.length,
    totalBots: appStateContext?.state.navigation?.length,
    reasonCount: reaosons.map((reason: any) => {
      return {
        name: reason.name,
        count: appStateContext?.state.feedbacks.filter((feedback: any) =>
          feedback.reasons.includes(String(reason.id)),
        ).length,
      };
    }),
  };

  const [newSummary, setNewSummary] = useState<any>(summary);

  const [botName, setBotName] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  useEffect(() => {
    appStateContext?.dispatch({
      type: 'CHANGE_PAGE',
      payload: 'admin/feedbacks',
    });
  }, []);

  useEffect(() => {
    setNewSummary({
      totalLikes: appStateContext?.state.feedbacks.filter(
        (feedback: any) =>
          feedback.isLiked &&
          (botName === '' ||
            (feedback.bot?.name?.toLowerCase()?.includes(botName.toLowerCase()) &&
              (!startDate ||
                new Date(feedback.createdAt) >= new Date(startDate)) &&
              (!endDate || new Date(feedback.createdAt) <= new Date(endDate)))),
      ).length,
      totalDislikes: appStateContext?.state.feedbacks.filter(
        (feedback: any) =>
          feedback.isDisliked &&
          (botName === '' ||
            (feedback.bot?.name?.toLowerCase()?.includes(botName.toLowerCase()) &&
              (!startDate ||
                new Date(feedback.createdAt) >= new Date(startDate)) &&
              (!endDate || new Date(feedback.createdAt) <= new Date(endDate)))),
      ).length,
      totalFeedbacks: appStateContext?.state.feedbacks.filter(
        (feedback: any) =>
          botName === '' ||
          (feedback.bot?.name?.toLowerCase()?.includes(botName.toLowerCase()) &&
            (!startDate ||
              new Date(feedback.createdAt) >= new Date(startDate)) &&
            (!endDate || new Date(feedback.createdAt) <= new Date(endDate))),
      ).length,

      totalUsers: appStateContext?.state.users.length,
      totalBots: appStateContext?.state.navigation?.length,

      reasonCount: reaosons.map((reason: any) => {
        return {
          name: reason.name,
          count: appStateContext?.state.feedbacks.filter(
            (feedback: any) =>
              feedback.reasons.includes(String(reason.id)) &&
              (botName === '' ||
                (feedback.bot?.name?.toLowerCase()?.includes(botName.toLowerCase()) &&
                  (!startDate ||
                    new Date(feedback.createdAt) >= new Date(startDate)) &&
                  (!endDate ||
                    new Date(feedback.createdAt) <= new Date(endDate)))),
          ).length,
        };
      }),
    });
  }, [startDate, endDate, botName, appStateContext?.state.feedbacks]);
  return (
    <>
      <Grid container spacing={2} p={1}>
        <Grid xs={12} item p={1}>
          <Typography variant="h5" padding={1}>
            Filter
          </Typography>
          <Stack direction="row" spacing={1}>
            <Chip
              label="Bot Name"
              onDelete={() => setBotName('')}
              color={botName === '' ? 'default' : 'primary'}
            />
            <Chip
              label="Start Date"
              onDelete={() => setStartDate(null)}
              color={startDate === null ? 'default' : 'primary'}
            />
            <Chip
              label="End Date"
              onDelete={() => setEndDate(null)}
              color={endDate === null ? 'default' : 'primary'}
            />
          </Stack>
        </Grid>

        <Grid xs={6} item p={1}>
          <TextField
            fullWidth
            label="Bot Name"
            value={botName}
            onChange={(e) => setBotName(e.target.value)}
          />
        </Grid>

        {/* mui date picker */}
        <Grid xs={3} item p={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              disabled={botName === ''}
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              views={['year','month', 'day']}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={3} item p={1} container>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              views={['month', 'day', 'year']}
              inputFormat="dd/MM/yyyy"
              value={endDate}
              disabled={botName === ''}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} item p={0}>
          <Divider />
        </Grid>
        <Grid xs={3} item p={1}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              appStateContext?.dispatch({
                type: 'DELETE_FEEDBACK',
                payload: selectedIds,
              });
            }}
            disabled={selectedIds.length === 0}
          >
            Delete Selection
          </Button>
        </Grid>
        <Grid xs={12} item p={1}>
          <DataGrid
            autoHeight
            style={{ marginTop: '10px' }}
            checkboxSelection
            //@ts-ignore
            onRowSelectionModelChange={(ids: any) => {
              setSelectedIds(ids);
            }}
            columns={[
              { field: "bot", headerName: "Bot", width: 70 ,
                renderCell: (params: any) => {
                  return params.row.bot?.name || 'Unknown';
                }
              },
              { field: 'botAnswer', headerName: 'Bot Answer', width: 130 },
              { field: 'userMessage', headerName: 'User Message', width: 130 },
              {
                field: 'isLiked',
                headerName: 'Like',
                type: 'boolean',
                width: 10,
                renderCell: (params: any) => {
                  return params.row.isLiked ? (
                    <ThumbUpIcon color="primary" />
                  ) : params.row.isDisliked ? (
                    <ThumbDownIcon color="primary" />
                  ) : (
                    <ThumbsUpDownIcon color="disabled" />
                  );
                },
              },
              {
                field: 'reasons',
                headerName: 'Reasons',
                width: 130,
                renderCell: (params: any) => (
                  <Tooltip
                    title={reaosons
                      .filter((reason: any) =>
                        params.row.reasons.includes(String(reason.id)),
                      )
                      .map((reason: any) => reason.name)
                      .join(', ')}
                  >
                    <Stack direction="row" spacing={1}>
                      {reaosons
                        .filter((reason: any) =>
                          params.row.reasons.includes(String(reason.id)),
                        )
                        .map((reason: any) => (
                          <Chip
                            size="small"
                            key={reason.id}
                            label={reason.name}
                          />
                        ))}
                    </Stack>
                  </Tooltip>
                ),
              },
              { field: 'feedback', headerName: 'Feedback', width: 200 },
              {
                field: 'createdAt',
                headerName: 'Created At',
                width: 200,
                renderCell: (params: any) => {
                  return new Date(params.row.createdAt).toLocaleString();
                },
              },
            ]}
            rows={appStateContext?.state?.feedbacks?.filter((feedback: any) => {
              return (
                botName === '' ||
                (feedback.bot?.name?.toLowerCase()?.includes(botName.toLowerCase()) &&
                  (!startDate ||
                    new Date(feedback.createdAt) >= new Date(startDate)) &&
                  (!endDate ||
                    new Date(feedback.createdAt) <= new Date(endDate)))
              );
            })}
          />
        </Grid>

        <Grid xs={12} item p={1}>
          <Divider />

          <Typography variant="h5" padding={1}>
            Summary
          </Typography>
          <DataGrid
            autoHeight
            columns={[
              { field: 'likes', headerName: 'Likes', width: 130 },
              { field: 'dislikes', headerName: 'Dislikes', width: 130 },
              { field: 'feedbacks', headerName: 'Feedbacks', width: 130 },
              { field: 'users', headerName: 'Users', width: 130 },
              { field: 'bots', headerName: 'Bots', width: 130 },
            ]}
            rows={[
              {
                id: 1,
                likes: newSummary.totalLikes,
                dislikes: newSummary.totalDislikes,
                feedbacks: newSummary.totalFeedbacks,
                users: newSummary.totalUsers,
                bots: newSummary.totalBots,
              },
            ]}
          />
          <Typography variant="h5" padding={1}>
            Dislike Reasons
          </Typography>
          <DataGrid
            autoHeight
            columns={[
              { field: 'reason', headerName: 'Reason', width: 400 },
              { field: 'count', headerName: 'Count', width: 130 },
            ]}
            rows={newSummary.reasonCount.map((reason: any, index: number) => {
              return {
                id: index + 1,
                reason: reason.name,
                count: reason.count,
              };
            })}
          />
        </Grid>
      </Grid>
    </>
  );
}
